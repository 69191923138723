import React, { useState, useRef } from 'react'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { navigate, Link } from '@reach/router';
import { setUser } from '../../app/infoSlice';
import { Checkbox, Button, Form, Input } from 'antd';
import logo from '../../assets/images/logo_creamo.jpg';
import styles from './login.module.css';
import {
  AlipayCircleOutlined,
  LockOutlined,
  MobileOutlined,
  TaobaoCircleOutlined,
  UserOutlined,
  WeiboCircleOutlined,
} from '@ant-design/icons';
import {
  LoginForm,
  ProFormCaptcha,
  ProFormCheckbox,
  ProFormText,
} from '@ant-design/pro-components';

import { useIntl } from "react-intl";


function Login(props) {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    // const inputRef = useRef(null);
    // const [Email, setEmail] = useState("")
    // const [Password, setPassword] = useState("")

    // const emitEmptyEmail = () => {
    //     inputRef.current.focus();
    //     setEmail('');
    //   };
    
    // const onEmailHandler = (event) => {
    //     setEmail(event.currentTarget.value)
    // }

    // const onPasswordHandler = (event) => {
    //     setPassword(event.currentTarget.value)
    // }

    const onFinish = (values) => {
        console.log(values)

        let body = {
            email: values.email,
            password: values.password
        }

        axios.post('/api/user/login', body).then(response => {

            console.log(response)
            if (response.data.success) {
                navigate('/');
                dispatch(setUser(response.data.user));
            } else {
                alert('Login Failed')
            }
          });
    }

    // const gotoLogin = (event) => {
    //     event.preventDefault();

    //     let body = {
    //         email: Email,
    //         password: Password
    //     }

    //     axios.post('/api/user/login', body).then(response => {

    //         console.log(response)
    //         if (response.data.success) {
    //             navigate('/');
    //             dispatch(setUser(response.data.user));
    //         } else {
    //             alert('Login Failed')
    //         }
    //       });

    // }

    // const userEmailSuffix = Email ? <Icon type="close-circle" onClick={emitEmptyEmail} /> : null;


    return (
      <div style={{ backgroundColor: 'white' }}>
        <LoginForm
          logo={logo}
          title="Creamo"
          subTitle="Smart Block"
          // actions={
          //   <Space>
          //     其他登录方式
          //     <AlipayCircleOutlined style={iconStyles} />
          //     <TaobaoCircleOutlined style={iconStyles} />
          //     <WeiboCircleOutlined style={iconStyles} />
          //   </Space>
          // }
          onFinish={onFinish}
        >
          <>
            <ProFormText
              name="email"
              fieldProps={{
                size: 'large',
                prefix: <UserOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Email'}
              rules={[
                {
                  required: true,
                  message: formatMessage({id: 'input.email'}),
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Password'}
              rules={[
                {
                  required: true,
                  message: formatMessage({id: 'input.password'}),
                },
              ]}
            />
          </>

          <div
            style={{
              marginBottom: 24,
            }}
          >
            <ProFormCheckbox noStyle name="autoLogin">
              {formatMessage({id: 'RememberMe'})}
            </ProFormCheckbox>
            <div
              style={{
                float: 'right',
              }}
            >
              <Link to="/register">
              {formatMessage({id: 'Regist'})}
              </Link>
            </div>
          </div>
        </LoginForm>
      </div>

    )
}

export default Login
