import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Space, Button, Popconfirm, Badge, Tooltip, Avatar } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import moment from 'moment';
import 'moment/locale/ko';
import {
  FileOutlined,
  ArrowLeftOutlined,
  SolutionOutlined,
  FormOutlined,
  DeleteOutlined,
  UserOutlined
} from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import 'antd/dist/antd.min.css';
import { useIntl } from "react-intl";

moment.locale('ko')

const OrgList = ({location}) => {

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { _id, role } = user;
  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [dataOffice, setDataOffice] = useState([]);
  const [dataDepart, setDataDepart] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, setHasSelected] = useState(selectedRowKeys.length > 0);
  
  const [pagination, setPagination] = useState({current:1, pageSize:10});
  const [loading, setLoading] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(false);
  const [tab, setTab] = useState('office');

  const { formatMessage } = useIntl();

  const handleOfficeChange = (pagination, filters, sorter) => {
    console.log("handleOfficeChange called")
    console.log(filters)
    fetchOffice({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
      uid: _id
    });
  };

  const handleDepartChange = (pagination, filters, sorter) => {
    console.log("handleOfficeChange called")
    console.log(filters)
    fetchOffice({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
      uid: _id
    });
  };


  const fetchOffice = (params = {}) => {
    setLoading(true);

    axios.post('/api/office/list', params).then(response => {

      console.log(response)
      if (response.data.success) {
        const offices = response.data.result;

        // orgs.forEach(v => {
        //   if (v.parentCode) {
        //     v.officeName = orgs.filter(el => el.officeCode === v.parentCode)[0]?.officeName
        //   }
        //   if(!v.departCode) v.departCode = ''
        //   if(!v.departName) v.departName = ''
        //   return v;
        // })

        setPagination({...params.pagination, total:response.data.total});
        setDataOffice(offices);

        setLoading(false);

      } else {
          setLoading(false);
          alert(response.data.error)
      }

    });
  };

  const deleteOffice = async () => {
    
    setVisiblePopconfirm(false);

    let param = {
      _ids: selectedRowKeys
    }

    console.log("param:" + param)
    const res = await axios.post('/api/office/delete', param)
    if (res.data.success) {
      // alert('삭제 되었습니다.')
    } else {
      // alert('삭제 실패 하였습니다.')
    }

    setSelectedRowKeys([]);
    setHasSelected(false)

    fetchOffice({
      pagination
    });

  }

  const fetchDepart = (params = {}) => {
    setLoading(true);

    axios.post('/api/depart/list', params).then(response => {

      console.log(response)
      if (response.data.success) {
        const departs = response.data.result;

        setPagination({...params.pagination, total:response.data.total});
        setDataDepart(departs);

        setLoading(false);

      } else {
          setLoading(false);
          alert(response.data.error)
      }

    });
  };

  const deleteDepart = async () => {
    
    setVisiblePopconfirm(false);

    let param = {
      _ids: selectedRowKeys
    }

    console.log("param:" + param)
    const res = await axios.post('/api/depart/delete', param)
    if (res.data.success) {
      // alert('삭제 되었습니다.')
    } else {
      // alert('삭제 실패 하였습니다.')
    }

    setSelectedRowKeys([]);
    setHasSelected(false)

    fetchDepart({
      pagination
    });

  }

  const getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            검색
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            초기화
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    // DB 필터링 사용 시는 주석처리
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
        // setTimeout(
        //   () => searchInput && searchInput.current && searchInput.current.select()
        // )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[setSearchText(searchText)]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  }
  
  const officeColumns = [
    {
      title: '소속',
      dataIndex: 'officeName',
      sorter: (a, b) => a.officeName.localeCompare(b.officeName),
      key: 'officeName',
      ...getColumnSearchProps('officeName'),
      render: (text,row) => {
        return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
        </div>
        )
      } 
    },
    // {
    //     title: '코드',
    //     dataIndex: 'officeCode',
    //     key: 'officeCode',
    //     width: '150px',
    //     sorter: (a, b) => a.officeCode.localeCompare(b.officeCode),
    //     ...getColumnSearchProps('officeCode'),
    //     render: (text,row) => {
    //         return (
    //         <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
    //             {text}
    //         </div>
    //         )
    //     } 
    // },
    {
      title: '순서',
      dataIndex: 'displayOrder',
      sorter: true,
      key: 'displayOrder',
      width: '90px',
      render: (text, row) => {
        return (
            <div>
              {text}
            </div>
          )
      } 
    }
  ];

  const departColumns = [
    {
      title: '반',
      dataIndex: 'departName',
      sorter: (a, b) => a.departName.localeCompare(b.departName),
      key: 'departName',
      ...getColumnSearchProps('departName'),
      // expandable: true,
      render: (text,row) => {
        return (
          <div>
            {text}
          </div>
        )
      } 
    },
    // {
    //     title: '코드',
    //     dataIndex: 'departCode',
    //     sorter: (a, b) => a.departCode.localeCompare(b.departCode),
    //     key: 'departCode',
    //     width: '150px',
    //     ...getColumnSearchProps('departCode'),
    //     expandable: true,
    //     render: (text,row) => {
    //       return (
    //         <div>
    //           {text}
    //         </div>
    //       )
    //     } 
    // },
    // {
    //   title: '상위코드',
    //   dataIndex: 'parentCode',
    //   sorter: (a, b) => a.departCode.localeCompare(b.departCode),
    //   key: 'parentCode',
    //   width: '150px',
    //   ...getColumnSearchProps('parentCode'),
    //   expandable: true,
    //   render: (text,row) => {
    //     return (
    //       <div>
    //         {text}
    //       </div>
    //     )
    //   } 
    // },
    {
      title: '소속',
      dataIndex: 'office',
      // sorter: (a, b) => a.departCode.localeCompare(b.departCode),
      key: 'office',
      width: '150px',
      // ...getColumnSearchProps('officeName'),
      expandable: true,
      render: (text,row) => {
        return (
          <div>
            {row["office"]?.officeName}
          </div>
        )
      } 
    },
    // {
    //   title: '순서',
    //   dataIndex: 'displayOrder',
    //   sorter: true,
    //   key: 'displayOrder',
    //   width: '90px',
    //   render: (text, row) => {
    //     return (
    //         <div>
    //           {text}
    //         </div>
    //       )
    //   } 
    // }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange : selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys)
      setHasSelected(selectedRowKeys.length > 0)
    },
    // selections: [
    //   Table.SELECTION_ALL,
    //   Table.SELECTION_INVERT,
    //   Table.SELECTION_NONE,
    // ],
  };

  useEffect(() => {

    fetchOffice({
      pagination,
    });

    fetchDepart({
      pagination,
    });

    // const data = [];
    // for (let i = 0; i < 46; i++) {
    //   data.push({
    //     key: i,
    //     templateTitle: `template title ${i}`,
    //     name: `Edward King ${i}`,
    //     requestedTime: `2021-07-02T05:46:40.769+00:00`,
    //   });
    // }
    // setData(data);

  }, []);


  const officeContent = (
    <Table
      rowKey={ item => { return item._id } }
      columns={officeColumns}
      dataSource={dataOffice}
      pagination={pagination}
      loading={loading}
      // expandedRowRender={row => < item={row} />}
      // expandRowByClickTemplateExpander
      rowSelection={rowSelection}
      onRow={record => ({
        onClick: e => {
          console.log(`org clicked on row ${record._id}!`);
          // navigate(`/userDetail`, { state: { userId: record._id } } );
        }
      })}
      onChange={handleOfficeChange}
    />
  )

  const departContent = (
    <Table
      rowKey={ item => { return item._id } }
      columns={departColumns}
      dataSource={dataDepart}
      pagination={pagination}
      loading={loading}
      // expandedRowRender={row => < item={row} />}
      // expandRowByClickTemplateExpander
      rowSelection={rowSelection}
      onRow={record => ({
        onClick: e => {
          console.log(`org clicked on row ${record._id}!`);
          // navigate(`/userDetail`, { state: { userId: record._id } } );
        }
      })}
      onChange={handleDepartChange}
    />
  )

  return (
    <div>
    <PageContainer
        ghost
        header={{
          title: formatMessage({id: 'org.manage'}),
          ghost: false,
          breadcrumb: {
            routes: [
              // {
              //   path: '/',
              //   breadcrumbName: 'Home',
              // },
              // {
              //   path: '../',
              //   breadcrumbName: '내 문서',
              // },
            ],
          },
          extra: [     
          <Button icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
            {/* {formatMessage({id: 'Back'})} */}
          </Button>, 
            <Button icon={<FormOutlined />} type="primary" onClick={() => {tab==='office' ? navigate('/officeAdd') : navigate('/departAdd')}}>
              {/* 등록 */}
            </Button>,

          <Popconfirm title="삭제하시겠습니까？" okText="네" cancelText="아니오" visible={visiblePopconfirm} onConfirm={tab==='office' ? deleteOffice : deleteDepart} onCancel={() => {setVisiblePopconfirm(false);}}>
            <Button icon={<DeleteOutlined />} type="primary" danger disabled={!hasSelected} onClick={()=>{setVisiblePopconfirm(true);}}>
            </Button>
          </Popconfirm>,
          <span>
            {hasSelected ? `${selectedRowKeys.length} 개 선택` : ''}
          </span>
          ],
        }}
        content={''}
        tabList={[
          {
            tab: '소속',
            key: 'office',
          },
          {
            tab: '반',
            key: 'depart',
          }
        ]}
        onTabChange={(key)=>{setTab(key)}}
        footer={[
        ]}
    >
      <br></br>
      {tab === 'office' ? officeContent : departContent}

    </PageContainer>
    </div>
    
  );
};

export default OrgList;
