import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Space, Button, Form, message, Upload, DatePicker } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import moment from 'moment';
import 'moment/locale/ko';
import {
  FileOutlined,
  ArrowLeftOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import { useIntl } from "react-intl";
import * as common from "../../util/common";

import ProCard from '@ant-design/pro-card';
import ProForm, { ProFormUploadDragger, ProFormText, ProFormSelect, ProFormDatePicker } from '@ant-design/pro-form';
import ImgCrop from 'antd-img-crop';

import '@ant-design/pro-card/dist/card.css';
import 'antd/dist/antd.min.css';
import '@ant-design/pro-form/dist/form.css';

const { TextArea } = Input;

const UserModify = ({location}) => {

  const { item } = location.state;

  const editorRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { _id } = user;

  const [form] = Form.useForm();

  const [disableNext, setDisableNext] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, setHasSelected] = useState(selectedRowKeys.length > 0);  
  const [pagination, setPagination] = useState({current:1, pageSize:10});
  const [loading, setLoading] = useState(false);


  const { formatMessage } = useIntl();
  const [fileList, setFileList] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [departList, setDepartList] = useState([]);
  const [officeOrg, setOfficeOrg] = useState([]);
  const [departOrg, setDepartOrg] = useState([]);

  const fileListDeleted = useRef([]);

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList)
    },
    beforeUpload: file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return Upload.LIST_IGNORE;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
        return Upload.LIST_IGNORE;
      }
      
      file.url = URL.createObjectURL(file)  // 업로드 전에 preview 를 위해 추가
      setFileList([...fileList, file])

      if (form.getFieldValue("name") && form.getFieldValue("date")) {
        setDisableNext(false)
      } else {
        setDisableNext(true)
      }

      return false;
    },
    fileList,
    onChange: (info) => {
      console.log(info)
      if (info.file.asis && info.file.status == 'removed') {  // 기존 파일인경우 status 로 구분 가능
        fileListDeleted.current.push(info.file)
      }
    },
    // onChange: ({ fileList: newFileList }) => {
    //     setFileList(newFileList)
    // },
    onPreview: async file => {
      console.log('aa', file)
      // let src = file.url;
      let src = URL.createObjectURL(file)
      console.log('src', src)
      if (!src) {
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    },
  };

  const onFinish = async (values) => {
    console.log(values)

    setLoading(true);
    
    // FILE UPLOAD
    // const filePaths = []
    var files = []
    console.log('fileList:'+fileList)
    if (fileList.length > 0) {  //uid 가 -1이면 기존에 업로드된 파일임

      const formData = new FormData()

      formData.append('path', 'thumbnails/');
      fileList.forEach(file => {
        if(!file.asis) {
          formData.append('files', file)
        } 
      });

      const resFile = await axios.post(`/api/storage/uploadFiles`, formData)
      if (resFile.data.success) {
        files = resFile.data.files
      }
    }

    // 기존 파일 추가 (삭제 항목 제외 후)
    item.thumbnails.forEach (file => {
      if (fileListDeleted.current.filter(deleteFile => deleteFile.url == file.path).length === 0) {
        files.push(file)
      }
    })
    
    // DB-SAVE
    let body = {
      user: item._id,
      name: form.getFieldValue("name"),
      birthday: form.getFieldValue("date"),
      // nickname: form.getFieldValue("nickname"),
      officeCode: form.getFieldValue("officeCode"), 
      office: officeOrg.filter(v => v.officeCode === form.getFieldValue("officeCode"))[0]?._id, 
      departCode: form.getFieldValue("departCode"), 
      depart: departOrg.filter(v => v.departCode === form.getFieldValue("departCode"))[0]?._id, 
      thumbnails: files.length > 0 ? files : [],
      filesDeleted: fileListDeleted.current
    }
    
    console.log(body)
    const res = await axios.post('/api/user/update', body)

    setLoading(false);
    navigate('/userList'); 
    
  }

  const fetchOfficeList = async (params = {}) => {
    setLoading(true);

    const res = await axios.post('/api/office/list', params)
    if(res.data.success) {
      let result = res.data.result

      let newData = {}
      result.forEach(el => newData[el.officeCode] = el.officeName)
      
      setOfficeList(newData)
      setOfficeOrg(result)
    }
    setLoading(false);
  };

  const fetchDepartList = async (params = {}) => {
    setLoading(true);

    const res = await axios.post('/api/depart/list', params)
    if(res.data.success) {
      let result = res.data.result

      let newData = {}
      result.forEach(el => newData[el.departCode] = el.departName)
      
      setDepartList(newData)
      setDepartOrg(result)
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log('item', item)

    fetchOfficeList(); 
    fetchDepartList({'officeCode': item.officeCode}) 

    if (item.thumbnails && item.thumbnails.length > 0) {
      let asisFileList = []

      item.thumbnails.forEach((file, index) => {
        asisFileList.push({
          uid: index,
          name: file.originalname,
          status: 'done',
          url: file.path,
          asis: true
        })
      })
    
      setFileList(asisFileList)
    }

  }, []);

  return (
    <div>
    <PageContainer
        ghost
        header={{
          title: formatMessage({id: 'user.modify'}),
          ghost: false,
          extra: [   
          <Button key="1" icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
            {/* {formatMessage({id: 'Back'})} */}
          </Button>,
          <Button key="2" icon={<ReloadOutlined />} onClick={() => form.resetFields()}>
            {/* {formatMessage({id: 'Initialize'})} */}
          </Button>,        
          <Button key="3" type="primary" disabled={disableNext} onClick={() => form.submit()}>
            {formatMessage({id: 'Save'})}
          </Button>
          ],
        }}
        footer={[
        ]}
    >
    <br />
    <ProCard direction="column" gutter={[0, 16]}>
      <ProForm 
        form={form}
        initialValues={{
          name: item?.name,
          date: item?.birthday,
          officeCode: item?.office?.officeCode,
          departCode: item?.depart?.departCode
        }}
        onFinish={onFinish}
        submitter={{
          // Configure the properties of the button
          resetButtonProps: {
            style: {
              // Hide the reset button
              display: 'none',
            },
          },
          submitButtonProps: {
            style: {
              // Hide the reset button
              display: 'none',
            },
          }
        }}
        onValuesChange={(changeValues) => {
          console.log("onValuesChange called")
          console.log(changeValues)

          if(changeValues.officeCode) {
            fetchDepartList({'officeCode': changeValues.officeCode})
            form.setFieldsValue({"departCode" : ""})
          }

          if (form.getFieldValue("name") && form.getFieldValue("officeCode")) {
            setDisableNext(false)
          } else {
            setDisableNext(true)
          }
        }}
      >

        <ImgCrop rotate>
          <Upload
            {...props}
            listType="picture-card"
            // listType="picture"
            // defaultFileList={[...fileList]}
          >
            {fileList.length < 1 && '+ Upload'}
          </Upload>
        </ImgCrop>

        <ProFormText
          name="name"
          label="이름"
          // width="md"
          // tooltip="입력하신 템플릿명으로 표시됩니다."
          placeholder="이름을 입력하세요."
          fieldProps={{
            size: 'large'          
          }}
          rules={[{ required: true, message: formatMessage({id: 'input.name'}) }]}
        />

        <ProFormDatePicker 
          name="date" 
          label="생일"
          fieldProps={{
            size: 'large'          
          }}
          // rules={[{ required: true, message: formatMessage({id: 'input.birthday'}) }]} 
          />

        <ProFormSelect
          name="officeCode"
          label="어린이집 선택"
          valueEnum={officeList}
          placeholder="어린이집을 선택하세요."
          fieldProps={{
            size: 'large'          
          }}
          rules={[{ required: true, message: '어린이집을 선택하세요.!' }]}
        />

        <ProFormSelect
          name="departCode"
          label="반 선택"
          valueEnum={departList}
          placeholder="반을 선택하세요."
          fieldProps={{
            size: 'large'          
          }}
          // rules={[{ required: true, message: '반을 선택하세요.!' }]}
        />
      <br></br>

    </ProForm>
  </ProCard>  
      
      

  </PageContainer>
  </div>
    
  );
};

export default UserModify;
