import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import { Button, Radio, Space, message } from 'antd';
import {
    EditOutlined,
    RadarChartOutlined,
    SolutionOutlined,
  } from '@ant-design/icons';
  import ProDescriptions from '@ant-design/pro-descriptions';
import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment/locale/ko';
import ico_bullet from '../../assets/images/table_bullet.png';

const UserExpander = (props) => {

    const { item, data, setData, officeCode } = props
    const user = useSelector(selectUser);
    const { _id, role } = user;
    const [roleValue, setRoleValue] = useState(item.role);

    useEffect(() => {
    }, []);

    const buttonList = (
        <Space>
            {(!item.email || _id === item._id || role === 1) ? //평가자의 경우 본인 또는 관리자만 수정 가능하게 함
            <Button icon={<EditOutlined />} onClick={()=> {
                navigate(`/userModify`, { state: { item: item, userId:  item._id } } );
            }} >수정</Button> : ''} 

            {!item.email && <Button
                icon={<SolutionOutlined />}
                onClick={() => {        
                navigate(`/recordAdd`, { state: { item: item, officeCode: officeCode } } );
            }}>평가</Button>}

            {!item.email && <Button icon={<RadarChartOutlined />} onClick={()=> {
                navigate(`/recordList`, { state: { item: item, officeCode: officeCode } } );
            }} >결과</Button> }
        </Space>
    )
   
    const onChange = async (e) => {
        console.log('radio checked', e.target.value);
        setRoleValue(e.target.value);

        let param = {
            user: item._id,
            role: e.target.value
        }
        const res = await axios.post('/api/user/updateRole', param)
        console.log(res)

        setData(data.map(el => {
            if (el._id === item._id) {
                el.role = e.target.value
            }
            return el;
        }))
    };

    return (
    <div>
        {/* <ProDescriptions bordered title={<div><img src={ico_bullet}></img>&nbsp;&nbsp;D</div>} tooltip="" contentStyle={{background:'white'}} extra={buttonList}> */}
        <ProDescriptions bordered title={<div>정보</div>} tooltip="" contentStyle={{background:'white'}} extra={buttonList}>    
            {!item.email && <ProDescriptions.Item label="이름">{item.name}</ProDescriptions.Item>}
            {!item.email && <ProDescriptions.Item label="소속">{item.office?.officeName}</ProDescriptions.Item>}
            {!item.email && <ProDescriptions.Item label="반">{item.depart?.departName}</ProDescriptions.Item>}
            {!item.email && <ProDescriptions.Item label="등록일">{moment(item.registeredTime).fromNow()}</ProDescriptions.Item>}
            {item.email && <ProDescriptions.Item label="권한 설정">
            <Radio.Group onChange={onChange} value={roleValue} disabled={role === 1 ? false : true}>
                <Radio value={0}>승인대기</Radio>
                <Radio value={1}>관리자</Radio>
                <Radio value={2}>직원</Radio>
            </Radio.Group>
                </ProDescriptions.Item>}
        </ProDescriptions>
    </div>
    );

};

export default UserExpander;