import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Result } from 'antd';
import { setUser } from '../../app/infoSlice';
import 'antd/dist/antd.css';
import { navigate } from '@reach/router';

const NoAuth = () => {

    const dispatch = useDispatch();

    return (
        <Result
        status="warning"
        title="관리자 이용 승인이 필요합니다."
        extra={
          <Button type="primary" key="console" onClick={() => {
            dispatch(setUser(null));  
            navigate('/')
          }}>
            Go Login
          </Button>
        }
      />
    );

};

export default NoAuth;