import React, { useState } from 'react'
import axios from 'axios';
import { navigate, Link } from '@reach/router';
import { Checkbox, Button, Form, Input, Space } from 'antd';
import ProForm, { ProFormCheckbox, ProFormText } from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import { PageContainer } from '@ant-design/pro-layout';
import logo from '../../assets/images/logo_creamo.jpg';
// import styles from './register.module.css';
import { useIntl } from "react-intl";
import {
  ReloadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import '@ant-design/pro-card/dist/card.css';
import 'antd/dist/antd.min.css';
import '@ant-design/pro-form/dist/form.css';
import styles from './register.module.css';

function Register(props) {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();

    const [disableNext, setDisableNext] = useState(true);

    const onFinish = (values) => {
        console.log(values)

        let body = {
            email: values.email,
            password: values.password,
            name: values.name
        }

        axios.post('/api/user/register', body).then(response => {

            console.log(response)
            if (response.data.success) {
                navigate('/');
                alert(formatMessage({id: 'register.success'}))
            } else {
                alert(response.data.error)
            }
          });
    }

    return (
        <>
        <div style={{paddingTop:'10px', paddingLeft:'5px', borderBottom:'1px solid grey', verticalAlign:'middle'}}>
        <Space>            
          <Link to='/'>
            <img src={logo} style={{height:30}} />
            <h2 style={{float:'right'}}>{formatMessage({id: 'AppName'})}</h2>
          </Link>
        </Space> 
        </div>

        <PageContainer
        ghost
        header={{
          title: formatMessage({id: 'Regist'}),
          ghost: false,
          extra: [   
            <Button key="1" icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
          </Button>,  
          <Button key="2" icon={<ReloadOutlined />} onClick={() => form.resetFields()}>
            {/* {formatMessage({id: 'Initialize'})} */}
          </Button>,        
          <Button key="3" type="primary" disabled={disableNext} onClick={() => form.submit()}>
            {formatMessage({id: 'Register'})}
          </Button>
          ],
        }}
        // content={'자주 사용하는 문서를 미리 등록할 수 있습니다.'}
        footer={[
        //   <div className={styles['footer']}>
        //   CREAMO © NICEHARU WORKS 2022
        // </div>
        ]}
        >
        <ProCard>
        <ProForm 
        form={form}
        initialValues={{
        }}
        onFinish={onFinish}
        submitter={{
          // Configure the properties of the button
          resetButtonProps: {
            style: {
              // Hide the reset button
              display: 'none',
            },
          },
          submitButtonProps: {
            style: {
              // Hide the reset button
              display: 'none',
            },
          }
        }}
        onValuesChange={(changeValues) => {
          console.log("onValuesChange called")
          console.log(changeValues)

          if (form.getFieldValue("email") && form.getFieldValue("password") && form.getFieldValue("name")) {
            setDisableNext(false)
          } else {
            setDisableNext(true)
          }
        }}
      >

        <ProFormText
          name="email"
          label={formatMessage({id: 'Email'})}
          width="md"
          // tooltip="입력하신 템플릿명으로 표시됩니다."
          placeholder={formatMessage({id: 'input.email'})}
          fieldProps={{
            size: 'large'          
          }}
          rules={[{ required: true, message: formatMessage({id: 'input.email'}) }]}
        />

        <ProFormText.Password  
          name="password" 
          label={formatMessage({id: 'Password'})}
          width="md"
          fieldProps={{
            size: 'large'          
          }}
          rules={[
            {
                required: true,
                min: 5,
                message: formatMessage({id: 'input.password'}),
            },
            ]} 
        />

        <ProFormText.Password  
          name="confirm" 
          label={formatMessage({id: 'ConfirmPassword'})}
          width="md"
          fieldProps={{
            size: 'large'          
          }}
          rules={[
            {
                required: true,
                message: formatMessage({id: 'confirm.password'}),
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                }

                return Promise.reject(new Error(formatMessage({id: 'nomatch.password'})));
                },
            }),
            ]}
        />

        <ProFormText
          name="name"
          label={formatMessage({id: 'Name'})}
          width="md"
          // tooltip="입력하신 템플릿명으로 표시됩니다."
          placeholder={formatMessage({id: 'input.name'})}
          fieldProps={{
            size: 'large'          
          }}
          rules={[{ required: true, message: formatMessage({id: 'input.name'}) }]}
        />

        <ProFormCheckbox noStyle name="agreement">
          {formatMessage({id: 'agree.agreement'})} <a href="">{formatMessage({id: 'view.agreement'})}</a>
        </ProFormCheckbox>


      </ProForm>

      </ProCard> 
      </PageContainer>

      </>
    )
}

export default Register