import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import axios from 'axios';
import { Tag, Timeline, Button, Alert, Modal, Badge, Popconfirm, Space, Image, Empty } from 'antd';
import Icon, {
    EditOutlined,
    RadarChartOutlined,
    SolutionOutlined,
    ExclamationCircleOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    BellFilled,
    DeleteOutlined,
    DownloadOutlined,
    FileProtectOutlined,
    PaperClipOutlined
  } from '@ant-design/icons';
import ProDescriptions from '@ant-design/pro-descriptions';
import 'antd/dist/antd.css';

const RecordExpander = (props) => {

    const { item, data, setData } = props
    const user = useSelector(selectUser);
    const { _id, role } = user;
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(false);

    const deleteRecord = async () => {
    
        setVisiblePopconfirm(false);
    
        let param = {
          _ids: [item._id]
        }
    
        console.log("param:" + param)
        const res = await axios.post('/api/record/delete', param)
        if (res.data.success) {
        //   alert('삭제 되었습니다.')
          setData(data.filter(el => el._id !== item._id))
        } else {
          // alert('삭제 실패 하였습니다.')
        }
        
        // fetch({
        //   pagination
        // });
    
      }

    useEffect(() => {
    }, []);

    const buttonList = (
      ( _id === item.rater._id || role === 1) ?
      <Space>
        <Button icon={<EditOutlined />} onClick={()=> {
          navigate(`/recordModify`, { state: { item: item, recordId:  item._id } } );
      }} >수정</Button>
        <Popconfirm title="삭제하시겠습니까？" okText="네" cancelText="아니오" visible={visiblePopconfirm} onConfirm={deleteRecord} onCancel={() => {setVisiblePopconfirm(false);}}>
            <Button icon={<DeleteOutlined />} type="primary" danger onClick={()=>{setVisiblePopconfirm(true);}}>
              삭제
            </Button>
        </Popconfirm>
      </Space> : ''
    ) 

    return (
    <div>
      <ProDescriptions bordered title={''} tooltip="" contentStyle={{background:'white'}} extra={buttonList}>
        
        <ProDescriptions.Item>
        {item.files?.length > 0 ? 
          <Space>
            <Image.PreviewGroup>
                {item.files?.map(el => {
                    console.log(el.path);
                    return <Image width={250} src={el.path} />
                })}
            </Image.PreviewGroup>
          </Space> :
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='이미지 없음' />}

        </ProDescriptions.Item>
      </ProDescriptions>

        {/* <br></br>
        <Space>
            <Image.PreviewGroup>
                {item.files?.map(el => {
                    console.log(el.path);
                    return <Image width={250} src={el.path} />
                })}
            </Image.PreviewGroup>
        </Space> */}
    </div>
    );

};

export default RecordExpander;