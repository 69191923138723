import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import axios from 'axios';
import { navigate, Link } from '@reach/router';
import { Spin, Button, Card, Avatar, Empty, List, message, Upload, Space } from 'antd';
import { selectUser, setOfficeList } from '../../app/infoSlice';
import { useIntl } from "react-intl";
import ProCard from '@ant-design/pro-card';
import 'antd/dist/antd.css';
import '@ant-design/pro-card/dist/card.css';
import '@ant-design/pro-form/dist/form.css';
import { UserOutlined, HomeOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import * as common from "../../util/common";
import icon_kids from "../../assets/images/icons8-kids-64.png";

const { Meta } = Card;

const Home = () => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { _id } = user;
  const { formatMessage } = useIntl();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    let param = {
      user: _id
    }
    
    const res = await axios.post('/api/office/list', param)
    if (res.data.success) {
      let result = res.data.result;
      setData(result)

      result.forEach(el => {
        el['text'] = el.officeName
        el['value'] = el.officeCode
      })

      dispatch(setOfficeList(result))
    }
    setLoading(false);

  }

  return (
    <div>

    {/* <PageContainer
        ghost
        header={{
          title: formatMessage({id: 'Home'}),
          ghost: false,
          breadcrumb: {
            routes: [
            ],
          },
          extra: [
          ],
        }}
        // content={'서명에 사용되는 사인을 미리 등록할 수 있습니다.'}
        footer={[
        ]}
    > */}
    
    <List
      rowKey="id"
      loading={loading}
      grid={{ gutter: 24, lg: 3, md: 3, sm: 2, xs: 2 }}
      dataSource={data}
      renderItem={item => (
        <List.Item key={item._id}>
          <Link to="/userList" state={{ officeName: item.officeName, officeCode: item.officeCode }}>
            <ProCard 
              hover
              colSpan="150px" 
              layout="center" 
              hoverable
              bordered
              style={{ minWidth: "150px" }}
              title={item.Button}
              icon={<HomeOutlined />}
              actions={[
                // <Button type="text" danger icon={<DeleteOutlined />} onClick={e => { deleteSign(item._id) }}>삭제</Button>,
                // <Button type="text" icon={<DownloadOutlined />} onClick={e => { downloadSign(item.signData) }}>다운로드</Button>
              ]}>

              {/* <Meta
                avatar={<Avatar icon={<HomeOutlined />} />}
                title={item.officeName}
                description="This is the description"
              /> */}
              <Space>
                <HomeOutlined /> 
                <font color='#252629'><b>{item.officeName}</b></font>
              </Space>

            </ProCard>
          </Link>
        </List.Item>
  
      )}
          />
    
    {/* </PageContainer> */}

    </div>
  );
};

export default Home;
