import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router, navigate } from '@reach/router';
import axios from 'axios';
import { setUser, selectUser } from './app/infoSlice';
import ProLayout, { DefaultFooter } from '@ant-design/pro-layout';
import Menus from './config/Menus';
import Home from './components/Home/Home';
import UserList from './components/User/UserList';
import UserAdd from './components/User/UserAdd';
import UserModify from './components/User/UserModify';
import RecordList from './components/Record/RecordList';
import RecordAdd from './components/Record/RecordAdd';
import RecordModify from './components/Record/RecordModify';
import BoardList from './components/Board/BoardList';
import BoardWrite from './components/Board/BoardWrite';
import BoardDetail from './components/Board/BoardDetail';
import BoardModify from './components/Board/BoardModify';
import OrgList from './components/Org/OrgList';
import OrgAdd from './components/Org/OrgAdd';
import CurriList from './components/Curriculum/CurriList';
import CurriAdd from './components/Curriculum/CurriAdd';
import OfficeAdd from './components/Org/OfficeAdd';
import DepartAdd from './components/Org/DepartAdd';
import NoAuth from './components/Common/NoAuth';

import Footer from './components/Footer/Footer';
import { view as Header } from './components/Header';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import '@ant-design/pro-layout/dist/layout.css';
import 'antd/dist/antd.min.css';
import logo from './assets/images/logo_creamo.jpg';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';

const App = () => {

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const [pathname, setPathname] = useState('/');  // 시작 path
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {

    axios.get('/api/user/auth').then(response => {
      if (!response.data.isAuth) {
          dispatch(setUser(null));
      } else {
          dispatch(setUser(response.data));
      }
    });

  }, []);

  return user ? (

    user?.role > 0 ? (
    <div
    id="test-pro-layout"
    style={{
      height: '100vh',
    }}
    >

    <ProLayout
      title="CREAMO"
      logo={logo}
      // logo="https://gw.alipayobjects.com/mdn/rms_b5fcc5/afts/img/A*1NHAQYduQiQAAAAAAAAAAABkARQnAQ" 로고 이미지 
      menuHeaderRender={(logo, title) => (
        <div
          id="customize_menu_header"
          onClick={() => {
            navigate('/')
            setCollapsed(true)
          }}
        >
          {logo}
          {title}
        </div>
      )}
      {...Menus()}
      location={{
        pathname,
      }}
      fixSiderbar={true}
      style={{
        // height: 500,
      }}
      collapsed={collapsed}
      onCollapse={(collapsed)=>{ setCollapsed(collapsed) }}
      menuItemRender={(item, dom) => (
        <a
          onClick={() => {
            setPathname(item.path)
            navigate(item.path)
            if (item.isMobile) {
              setCollapsed(true)
            }
          }}
        >
          {dom}
        </a>
      )}
      rightContentRender={() => (
        <div>
          <Header />
        </div>
      )}
      footerRender={() => (
        // <div>
        //   <Footer />
        // </div>
        <DefaultFooter
          links={[
            { key: '이용약관', title: '이용약관', href: 'terms' },
            { key: '개인정보처리방침', title: '개인정보처리방침', href: 'policy' },
          ]}
          copyright="CREAMO © NICEHARU STUDIO 2021"
        />
      )}     
  >
    <Router>
        <Home path="/" />
        <UserList path="/userList" />
        <UserAdd path="/userAdd" />
        <UserModify path="/userModify" />
        <RecordList path="/recordList" />
        <RecordAdd path="/recordAdd" />
        <RecordModify path="/recordModify" />
        <BoardList path="/boardList" />
        <BoardWrite path="/boardWrite" />
        <BoardDetail path="/boardDetail" />
        <BoardModify path="/boardModify" />
        <OrgList path="/orgList" />
        <OrgAdd path="/orgAdd" />
        <CurriList path="/curriList" />
        <CurriAdd path="/curriAdd" />
        <OfficeAdd path="/officeAdd" />
        <DepartAdd path="/departAdd" />
      </Router>
  </ProLayout>

  </div>) : (<div><NoAuth /></div>)

  ) : (
    <div>
      {/* <Header /> */}
      <Router>
        <Login path="/" default />
        <Register path="/register" />
      </Router>
    </div>
  );


}

export default App;
