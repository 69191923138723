import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Space, Button, Popconfirm, Badge, Tooltip, Avatar } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import moment from 'moment';
import 'moment/locale/ko';
import {
  FileOutlined,
  ArrowLeftOutlined,
  SolutionOutlined,
  FormOutlined,
  DeleteOutlined,
  UserOutlined
} from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import 'antd/dist/antd.min.css';
import { useIntl } from "react-intl";

moment.locale('ko')

const CurriList = ({location}) => {

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { _id, role } = user;
  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, setHasSelected] = useState(selectedRowKeys.length > 0);
  
  const [pagination, setPagination] = useState({current:1, pageSize:10});
  const [loading, setLoading] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(false);

  const { formatMessage } = useIntl();

  const handleChange = (pagination, filters, sorter) => {
    console.log("handleOfficeChange called")
    console.log(filters)
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
      uid: _id
    });
  };


  const fetch = (params = {}) => {
    setLoading(true);

    axios.post('/api/curri/list', params).then(response => {

      console.log(response)
      if (response.data.success) {
        const results = response.data.result;

        // orgs.forEach(v => {
        //   if (v.parentCode) {
        //     v.officeName = orgs.filter(el => el.officeCode === v.parentCode)[0]?.officeName
        //   }
        //   if(!v.departCode) v.departCode = ''
        //   if(!v.departName) v.departName = ''
        //   return v;
        // })

        setPagination({...params.pagination, total:response.data.total});
        setData(results);

        setLoading(false);

      } else {
          setLoading(false);
          alert(response.data.error)
      }

    });
  };

  const deleteCurries = async () => {
    
    setVisiblePopconfirm(false);

    let param = {
      _ids: selectedRowKeys
    }

    console.log("param:" + param)
    const res = await axios.post('/api/curri/delete', param)
    if (res.data.success) {
      // alert('삭제 되었습니다.')
    } else {
      // alert('삭제 실패 하였습니다.')
    }

    setSelectedRowKeys([]);
    setHasSelected(false)

    fetch({
      pagination
    });

  }

  const getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            검색
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            초기화
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    // DB 필터링 사용 시는 주석처리
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
        // setTimeout(
        //   () => searchInput && searchInput.current && searchInput.current.select()
        // )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[setSearchText(searchText)]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  }
  
  const columns = [
    {
      title: '회차',
      dataIndex: 'no',
      sorter: true,
      key: 'no',
      width: '110px',
      render: (text, row) => {
        return (
            <div>
              {text}
            </div>
          )
      } 
    },  
    // {
    //   title: '월',
    //   dataIndex: 'month',
    //   sorter: true,
    //   key: 'month',
    //   width: '50px',
    //   render: (text, row) => {
    //     return (
    //         <div>
    //           {text}
    //         </div>
    //       )
    //   } 
    // },
    // {
    //   title: '주차',
    //   dataIndex: 'weeks',
    //   sorter: true,
    //   key: 'weeks',
    //   width: '80px',
    //   render: (text, row) => {
    //     return (
    //         <div>
    //           {text}
    //         </div>
    //       )
    //   } 
    // },
    {
      title: '생활주제',
      dataIndex: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
      key: 'category',
      width: '240px',
      ...getColumnSearchProps('category'),
      render: (text,row) => {
        return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
        </div>
        )
      } 
    },
    {
        title: '주제',
        dataIndex: 'subject',
        key: 'subject',
        width: '240px',
        sorter: (a, b) => a.subject.localeCompare(b.subject),
        ...getColumnSearchProps('subject'),
        render: (text,row) => {
            return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                {text}
            </div>
            )
        } 
    },
    {
      title: '내용',
      dataIndex: 'content',
      responsive: ["sm"],
      sorter: true,
      key: 'content',
      render: (text, row) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
              {text}
            </div>
          )
      } 
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange : selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys)
      setHasSelected(selectedRowKeys.length > 0)
    },
    // selections: [
    //   Table.SELECTION_ALL,
    //   Table.SELECTION_INVERT,
    //   Table.SELECTION_NONE,
    // ],
  };

  useEffect(() => {

    fetch({
      pagination,
    });

  }, []);


  const content = (
    <Table
      rowKey={ item => { return item._id } }
      columns={columns}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      // expandedRowRender={row => < item={row} />}
      // expandRowByClickTemplateExpander
      rowSelection={rowSelection}
      onRow={record => ({
        onClick: e => {
          console.log(`org clicked on row ${record._id}!`);
          // navigate(`/userDetail`, { state: { userId: record._id } } );
        }
      })}
      onChange={handleChange}
    />
  )


  return (
    <div>
    <PageContainer
        ghost
        header={{
          title: formatMessage({id: 'curriculum.manage'}),
          ghost: false,
          breadcrumb: {
            routes: [
              // {
              //   path: '/',
              //   breadcrumbName: 'Home',
              // },
              // {
              //   path: '../',
              //   breadcrumbName: '내 문서',
              // },
            ],
          },
          extra: [     
          <Button icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
            {/* {formatMessage({id: 'Back'})} */}
          </Button>, 
            <Button icon={<FormOutlined />} type="primary" onClick={() => navigate('/curriAdd')}>
              {/* 등록 */}
            </Button>,

          <Popconfirm title="삭제하시겠습니까？" okText="네" cancelText="아니오" visible={visiblePopconfirm} onConfirm={deleteCurries} onCancel={() => {setVisiblePopconfirm(false);}}>
            <Button icon={<DeleteOutlined />} type="primary" danger disabled={!hasSelected} onClick={()=>{setVisiblePopconfirm(true);}}>
            </Button>
          </Popconfirm>,
          <span>
            {hasSelected ? `${selectedRowKeys.length} 개 선택` : ''}
          </span>
          ],
        }}
        content={''}
        footer={[
        ]}
    >
      <br></br>
      {content}

    </PageContainer>
    </div>
    
  );
};

export default CurriList;
