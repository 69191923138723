// import { useMemo } from "react"
import React from 'react';
import { useIntl } from "react-intl";
import { CopyOutlined, UserOutlined, SettingOutlined, FileTextOutlined, HighlightOutlined } from '@ant-design/icons';

export default function Menus() {
  const { formatMessage } = useIntl();
  const data = {
    route: {
      path: '/',
      routes: [
        // {
        //   path: '/raterList',
        //   name: formatMessage({id: 'rater'}),
        //   icon: <UserOutlined />,
        // },
        {
          path: '/userList',
          name: formatMessage({id: 'user.manage'}),
          icon: <UserOutlined />,
        },
        {
          path: '/orgList',
          name: formatMessage({id: 'org.manage'}),
          icon: <FileTextOutlined />,
        },
        {
          path: '/curriList',
          name: formatMessage({id: 'curriculum.manage'}),
          icon: <FileTextOutlined />,
        },
        {
          path: '/boardList',
          name: formatMessage({id: 'board'}),
          icon: <CopyOutlined />,
        },
      ],
    },
    location: {
      pathname: '/',
    }
  }

  return data;
}