import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Space, Button, Popconfirm, Checkbox, Tooltip, Avatar, message } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectOfficeList, selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import UserExpander from "./UserExpander";
import moment from 'moment';
import 'moment/locale/ko';
import {
  RadarChartOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  SolutionOutlined,
  FormOutlined,
  DeleteOutlined,
  UserOutlined
} from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import 'antd/dist/antd.min.css';
import { useIntl } from "react-intl";
import useDidMountEffect from '../Common/useDidMountEffect';

moment.locale('ko')

const UserList = ({location}) => {

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  
  const { _id, role } = user;

  const officeName = location?.state?.officeName;
  const officeCode = location?.state?.officeCode;
  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [officeList, setOfficeList] = useState(useSelector(selectOfficeList));  // 바로 통신하면 onChange 이벤트에 변화감지를 못해서 redux에 저장해 둔 값 바로 셋팅
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, setHasSelected] = useState(selectedRowKeys.length > 0);
  
  const [pagination, setPagination] = useState({current:1, pageSize:10});
  const [loading, setLoading] = useState(false);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(false);
  const [includeRater, setIncludeRater] = useState(false);

  const info = useRef();

  const { formatMessage } = useIntl();

  const handleTableChange = (pagination, filters, sorter, action) => {
    console.log("handleTableChange called")
    console.log('filters', filters)
    console.log('action', action)

    console.log('info', info.current)
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
      uid: _id
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);

    axios.post('/api/user/list', params).then(response => {

      console.log(response)
      if (response.data.success) {
        const users = response.data.result;

        setPagination({...params.pagination, total:response.data.total});
        setData(users);

        setLoading(false);

      } else {
          setLoading(false);
          alert(response.data.error)
      }

    });
  };

  const fetchOfficeList = async (params = {}) => {
    setLoading(true);

    const res = await axios.post('/api/office/list', params)
    if(res.data.success) {
      let result = res.data.result

      // let newData = []
      
      // result.forEach(el => {
      //   let val = {}
      //   val['text'] = el.officeName
      //   val['value'] = el.officeCode
      //   newData.push(val)
      // })

      result.forEach(el => {
        el['text'] = el.officeName
        el['value'] = el.officeCode
      })

      console.log('result', result)

      setOfficeList(result)
    }
    setLoading(false);
  };

  const roleName = (role) => {
    if (role === 0) {
      return '승인대기'
    } else if (role === 1) {
      return '관리자'
    } else if (role === 2) {
      return '직원'
    }
  }

  const deleteUsers = async () => {

    
    setVisiblePopconfirm(false);

    //권한체크
    if (includeRater) {
      if (role !== 1) { //평가자는 관리자만 삭제 가능하게 함
        message.info('삭제 권한이 없습니다.')
        return;
      }
    } else {
      if (role < 1) {
        message.info('삭제 권한이 없습니다.')
        return;
      }
    }

    let param = {
      _ids: selectedRowKeys
    }

    console.log("param:" + param)
    const res = await axios.post('/api/user/delete', param)
    if (res.data.success) {
      // alert('삭제 되었습니다.')
    } else {
      // alert('삭제 실패 하였습니다.')
    }

    setSelectedRowKeys([]);
    setHasSelected(false)

    fetch({
      pagination
    });

  }

  const getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            검색
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            초기화
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    // DB 필터링 사용 시는 주석처리
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
        // setTimeout(
        //   () => searchInput && searchInput.current && searchInput.current.select()
        // )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[setSearchText(searchText)]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  }
  
  const columns = [
    {
      title: '이름',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      key: 'name',
      responsive: ["xs"],
      width: '200px',
      ...getColumnSearchProps('name'),
      expandable: true,
      render: (text,row) => {
        return (
          <div>
            {(row["thumbnails"] && row["thumbnails"].length >0) ? <Avatar shape="square" src={row["thumbnails"][0].path}/> : <Avatar shape="square" icon={<UserOutlined />} />}
            <br></br>{text}
          </div>
        )
      } 
    },
    {
      title: '이름',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      key: 'name',
      responsive: ["sm"],
      width: '200px',
      ...getColumnSearchProps('name'),
      expandable: true,
      render: (text,row) => {
        return (
          <div>
            {(row["thumbnails"] && row["thumbnails"].length >0) ? <Avatar shape="square" src={row["thumbnails"][0].path}/> : <Avatar shape="square" icon={<UserOutlined />} />}
            &nbsp;{text}
          </div>
        )
      } 
    },
    {
      title: '소속',
      dataIndex: 'officeCode',
      sorter: (a, b) => a.name.localeCompare(b.name),
      key: 'officeCode',
      // ...getColumnSearchProps('officeName'),
      // expandable: true,
      defaultFilteredValue: officeCode? [officeCode]: [],
      filterMultiple: false,
      // filters: [
      //   {
      //     text: '어린이집1',
      //     value: 'C000001',
      //   },
      //   {
      //     text: '어린이집3',
      //     value: 'C000003',
      //   }
      // ],
      filters: officeList,
      onFilter: (value, record) => record['office']?.officeCode.indexOf(value) === 0,
      width: '110px',
      render: (text,row) => {
        return (
          <div>
            {row['office']?.officeName}
          </div>
        )
      } 
    },
    {
      title: includeRater ? '권한' : '반',
      dataIndex: 'depart',
      sorter: (a, b) => a.name.localeCompare(b.name),
      key: 'departName',
      ...getColumnSearchProps( includeRater ? '권한' : '반'),
      // expandable: true,
      width: '110px',
      render: (text,row) => {
        return (
          <div>
            {includeRater ? roleName(row.role) : row['depart']?.departName}
          </div>
        )
      } 
    },
    {
      title: '등록일',
      dataIndex: 'registeredTime',
      responsive: ["sm"],
      sorter: true,
      key: 'registeredTime',
      width: '110px',
      render: (text, row) => {
        return (<font color='#787878'>{moment(row["registeredTime"]).fromNow()}</font>)
      } 
    },
    {
      title: '',
      // dataIndex: 'registeredTime',
      // sorter: true,
      key: 'action',
      width: '80px',
      responsive: ["sm"],
      render: (text, row) => {
        return (
          <div>           
          {!row["email"] &&
           <Tooltip placement="top" title={'평가보기'}>
           <Button icon={<RadarChartOutlined />} onClick={()=> {
               navigate(`/recordList`, { state: { item: row } } );
           }} ></Button>
          </Tooltip>} 
          </div>
        )
      } 
    },
  ];


  const rowSelection = {
    selectedRowKeys,
    onChange : selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys)
      setHasSelected(selectedRowKeys.length > 0)
    },
    // selections: [
    //   Table.SELECTION_ALL,
    //   Table.SELECTION_INVERT,
    //   Table.SELECTION_NONE,
    // ],
  };

  useEffect(() => {
  
    // redux에 값이 없는 경우 새로 호출
    if (officeList?.length === 0) fetchOfficeList();

    fetch({
      pagination,
      officeCode : officeCode ? [officeCode] : null
    });

  }, []);

    // 화면이 처음 로딩시에는 호출되지 않도록 함
    useDidMountEffect(() => {

      console.log("useEffect called includeRater : " + includeRater)
  
      fetch({
        pagination,
        includeRater
      });
  
      // console.log("location.state.docId:"+ location.state.docId)
  
    }, [includeRater]);

    
  return (
    <div>
    <PageContainer
        ghost
        header={{
          title: officeName ? officeName : formatMessage({id: 'UserList'}),
          ghost: false,
          breadcrumb: {
            routes: [
              // {
              //   path: '/',
              //   breadcrumbName: 'Home',
              // },
              // {
              //   path: '../',
              //   breadcrumbName: '내 문서',
              // },
            ],
          },
          extra: [     
            <Checkbox key='chk' checked={includeRater} onChange={(e) => {setIncludeRater(e.target.checked)}}>평가자</Checkbox>,
          <Button icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
            {/* {formatMessage({id: 'Back'})} */}
          </Button>, 
            <Button icon={<FormOutlined />} type="primary" onClick={() => {navigate('/userAdd', { state: {officeCode:officeCode}});}}>
              {/* 등록 */}
            </Button>, 
          <Popconfirm title="삭제하시겠습니까？" okText="네" cancelText="아니오" visible={visiblePopconfirm} onConfirm={deleteUsers} onCancel={() => {setVisiblePopconfirm(false);}}>
            <Button icon={<DeleteOutlined />} type="primary" danger disabled={!hasSelected} onClick={()=>{setVisiblePopconfirm(true);}}>
            </Button>
          </Popconfirm>,
          <span>
            {hasSelected ? `${selectedRowKeys.length} 명 선택` : ''}
          </span>
          ],
        }}
        content={''}
        footer={[
        ]}
    >
      <br></br>
      <Table
        rowKey={ item => { return item._id } }
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        defaultExpandedRowKeys={[location.state._id]}
        expandedRowRender={row => <UserExpander item={row} data={data} setData={setData} officeCode={officeCode} />}
        expandRowByClick
        rowSelection={rowSelection}
        onRow={record => ({
          onClick: e => {
            console.log(`user clicked on row ${record._id}!`);
            // navigate(`/userDetail`, { state: { userId: record._id } } );
          }
        })}
        onChange={handleTableChange}
      />

    </PageContainer>
    </div>
    
  );
};

export default UserList;
