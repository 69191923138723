import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Space, Button, Form, message, Upload, DatePicker } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import moment from 'moment';
import 'moment/locale/ko';
import {
  FileOutlined,
  ArrowLeftOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import { useIntl } from "react-intl";
import * as common from "../../util/common";

import ProCard from '@ant-design/pro-card';
import ProForm, { ProFormTextArea, ProFormText, ProFormRadio, ProFormUploadButton, ProFormSelect } from '@ant-design/pro-form';
import ImgCrop from 'antd-img-crop';

import {
  ProFormCascader
} from '@ant-design/pro-components';


import '@ant-design/pro-card/dist/card.css';
import 'antd/dist/antd.min.css';
import '@ant-design/pro-form/dist/form.css';


const { TextArea } = Input;

const RecordAdd = ({location}) => {

  const { item, officeCode } = location.state;

  const editorRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { _id } = user;

  const [form] = Form.useForm();

  const [disableNext, setDisableNext] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, setHasSelected] = useState(selectedRowKeys.length > 0);  
  const [pagination, setPagination] = useState({current:1, pageSize:10});
  const [loading, setLoading] = useState(false);


  const { formatMessage } = useIntl();
  const [fileList, setFileList] = useState([]);
  const [curriList, setCurriList] = useState([]);
  const [curriStepList, setCurriStepList] = useState([]);
  const [departList, setDepartList] = useState([]);
  const [officeOrg, setOfficeOrg] = useState([]);
  const [departOrg, setDepartOrg] = useState([]);

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList)
    },
    beforeUpload: file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return Upload.LIST_IGNORE;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Image must smaller than 10MB!');
        return Upload.LIST_IGNORE;
      }
      
      file.url = URL.createObjectURL(file)  // 업로드 전에 preview 를 위해 추가
      setFileList([...fileList, file])

      return false;
    },
    fileList,
    // onChange: (info) => {
    //   console.log(info)
    //   if (info.fileList.length != fileList.length) { // 파일 삭제 된 것으로 판단
    //     setFileList(fileList.filter(e => e.uid != info.file.uid)) // fileList(State Object)에 파일 삭제
    //   }
    // },
    // onChange: ({ fileList: newFileList }) => {
    //     setFileList(newFileList)
    // },
    onPreview: async file => {
      console.log('aa', file)
      // let src = file.url;
      let src = URL.createObjectURL(file)
      console.log('src', src)
      if (!src) {
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    },
  };

  const onFinish = async (values) => {
    console.log(values)

    setLoading(true);
    
    // FILE UPLOAD
    var files = []
    console.log('fileList:'+fileList)
    if (fileList.length > 0) {

      const formData = new FormData()

      formData.append('path', 'records/'+Date.now()+'/');
      fileList.forEach(file => formData.append('files', file));

      const resFile = await axios.post(`/api/storage/uploadFiles`, formData)
      if (resFile.data.success) {
        files = resFile.data.files
      }
    }

    if (form.getFieldValue("curri").length < 2) {
      message.info('커리큘럼을 선택하세요!');
      return;
    }

    // DB-SAVE
    let body = {
      user: item._id,
      rater: _id,
      // curri: form.getFieldValue("curri"),
      curri: form.getFieldValue("curri")[1],
      comment: form.getFieldValue("comment"),
      evalItem1: form.getFieldValue("evalItem1"), 
      evalItem2: form.getFieldValue("evalItem2"), 
      evalItem3: form.getFieldValue("evalItem3"), 
      files: files.length > 0 ? files : []
    }
    console.log(body)
    const res = await axios.post('/api/record/add', body)

    setLoading(false);
    // navigate('/userList'); 

    navigate(`/recordList`, { state: { item: item, officeCode: officeCode } } );
    
  }

  const fetchCurriList = async (params = {}) => {
    setLoading(true);

    const res = await axios.post('/api/curri/list', params)
    if(res.data.success) {
      let result = res.data.result

      let newData = {}
      result.forEach(el => newData[el._id] = el.no +'. '+ el.subject)
      

      // 모바일 스크롤이 힘들어 구분해서 데이터 삽입
      let stepData = [];
      for(let i=1; i<6; i++) {
        
        let newChilds = []
        result.filter(el => i === 1 ? el.no <= 10 : (el.no <= i*10 && el.no > (i-1)*10) ).forEach(el => {
          let newChild = {}
          newChild['value'] = el._id
          newChild['label'] = el.no +'. '+ el.subject
          newChilds.push(newChild)
        })
        stepData.push({label : i === 1 ? i + '~' : (i - 1) * 10 + '~', value: i * 10 + '', children: newChilds})
      }
      setCurriStepList(stepData);


      setCurriList(newData)
      setData(result)
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCurriList({"pagination" : {"current":1,"pageSize":100}});
  }, []);

  return (
    <div>
    <PageContainer
        ghost
        header={{
          title: item?.name +' '+formatMessage({id: 'record.add'}),
          ghost: false,
          extra: [   
          <Button key="1" icon={<ArrowLeftOutlined />} onClick={() => 
            window.history.back()
            // navigate("/userList")
          }>
            {/* {formatMessage({id: 'Back'})} */}
          </Button>,
          <Button key="2" icon={<ReloadOutlined />} onClick={() => form.resetFields()}>
            {/* {formatMessage({id: 'Initialize'})} */}
          </Button>,        
          <Button key="3" type="primary" disabled={disableNext} onClick={() => form.submit()}>
            {formatMessage({id: 'Save'})}
          </Button>
          ],
        }}
        // content={'자주 사용하는 문서를 미리 등록할 수 있습니다.'}
        footer={[
        ]}
    >
    <br />
    <ProCard direction="column" gutter={[0, 16]}>
      <ProForm 
        form={form}
        onFinish={onFinish}
        submitter={{
          // Configure the properties of the button
          resetButtonProps: {
            style: {
              // Hide the reset button
              display: 'none',
            },
          },
          submitButtonProps: {
            style: {
              // Hide the reset button
              display: 'none',
            },
          }
        }}
        onValuesChange={(changeValues) => {
          console.log("onValuesChange called")
          console.log(changeValues)


          if ((form.getFieldValue("curri") && 
              form.getFieldValue("evalItem1") && 
              form.getFieldValue("evalItem2") && 
              form.getFieldValue("evalItem3")) || fileList.length > 0 || form.getFieldValue("comment")) {
            setDisableNext(false)
          } else {
            setDisableNext(true)
          }
        }}
      >

        {/* <ProFormSelect
          name="curri"
          label="커리큘럼 선택"
          // valueEnum={{
          //   china: 'China',
          //   usa: 'U.S.A',
          // }}
          valueEnum={curriList}
          placeholder="커리큘럼을 선택하세요."
          rules={[{ required: true, message: '커리큘럼을 선택하세요.!' }]}
        /> */}

        <ProFormCascader
          width="md"
          // valueEnum={curriStepList}
          fieldProps={{
            options: curriStepList,
            size: 'large'
          }}
          name="curri"
          label="커리큘럼 선택"
          // initialValue={['zhejiang', 'hangzhou', 'xihu']}
          // addonAfter={'qixian'}
          rules={[{ required: true, message: '커리큘럼을 선택하세요.!' }]}
        />

        <ProFormRadio.Group
          name="evalItem1"
          label="논리력/사고력"
          rules={[{ required: true }]}
          options={[
            {
              label: '상',
              value: '100',
            },
            {
              label: '중',
              value: '66',
            },
            {
              label: '하',
              value: '33',
            },
          ]}
        />

        <ProFormRadio.Group
          name="evalItem2"
          label="학업성취도"
          rules={[{ required: true }]}
          options={[
            {
              label: '상',
              value: '99',
            },
            {
              label: '중',
              value: '66',
            },
            {
              label: '하',
              value: '33',
            },
          ]}
        />

        <ProFormRadio.Group
          name="evalItem3"
          label="사회성과 표현력"
          rules={[{ required: true }]}
          options={[
            {
              label: '상',
              value: '99',
            },
            {
              label: '중',
              value: '66',
            },
            {
              label: '하',
              value: '33',
            },
          ]}
        />

        <ProFormTextArea 
          name="comment" 
          label="코멘트" 
          width="lg" 
          placeholder={formatMessage({id: 'input.comment'})} 
          fieldProps={{
            size: 'large'          
          }}
          rules={[{ required: false, message: formatMessage({id: 'input.comment'}) }]}
        />

        {/* <ImgCrop rotate> */}
          <Upload
            {...props}
            listType="picture-card"
            // listType="picture"
          >
            {fileList.length < 5 && '+ Upload'}
          </Upload>
        {/* </ImgCrop> */}

    </ProForm>
  </ProCard>  
      
      

  </PageContainer>
  </div>
    
  );
};

export default RecordAdd;
