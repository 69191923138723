import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Space, Button, Form, message, Upload, DatePicker } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import moment from 'moment';
import 'moment/locale/ko';
import {
  FileOutlined,
  ArrowLeftOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import { useIntl } from "react-intl";
import * as common from "../../util/common";

import ProCard from '@ant-design/pro-card';
import ProForm, { ProFormUploadDragger, ProFormText, ProFormRadio, ProFormSelect } from '@ant-design/pro-form';
import ImgCrop from 'antd-img-crop';

import '@ant-design/pro-card/dist/card.css';
import 'antd/dist/antd.min.css';
import '@ant-design/pro-form/dist/form.css';


const { TextArea } = Input;

const DepartAdd = ({location}) => {

  const editorRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { _id } = user;

  const [form] = Form.useForm();

  const [disableNext, setDisableNext] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, setHasSelected] = useState(selectedRowKeys.length > 0);  
  const [pagination, setPagination] = useState({current:1, pageSize:10});
  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();
  const [fileList, setFileList] = useState([]);
  const [officeList, setOfficeList] = useState([]);

  const props = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList)
    },
    beforeUpload: file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return Upload.LIST_IGNORE;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
        return Upload.LIST_IGNORE;
      }
      
      file.url = URL.createObjectURL(file)  // 업로드 전에 preview 를 위해 추가
      setFileList([...fileList, file])

      return false;
    },
    fileList,
    onChange: (info) => {
      console.log(info)
      if (info.fileList.length != fileList.length) { // 파일 삭제 된 것으로 판단
        setFileList(fileList.filter(e => e.uid != info.file.uid)) // fileList(State Object)에 파일 삭제
      }
    },
    // onChange: ({ fileList: newFileList }) => {
    //     setFileList(newFileList)
    // },
    onPreview: async file => {
      console.log('aa', file)
      // let src = file.url;
      let src = URL.createObjectURL(file)
      console.log('src', src)
      if (!src) {
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    },
  };

  const onFinish = async (values) => {
    console.log(values)

    setLoading(true);
    
    // FILE UPLOAD
    // const filePaths = []
    // var files = []
    // console.log('fileList:'+fileList)
    // if (fileList.length > 0) {

    //   const formData = new FormData()

    //   formData.append('path', 'thumbnails/');
    //   fileList.forEach(file => formData.append('files', file));

    //   const resFile = await axios.post(`/api/storage/uploadFiles`, formData)
    //   if (resFile.data.success) {
    //     resFile.data.files.map(file => {
    //       filePaths.push(file.path)
    //     })

    //     files = resFile.data.files
    //   }
    // }

    // console.log('filePaths:'+filePaths)
    

    // DB-SAVE
    let body = {
      office: data.filter(v => v.officeCode === form.getFieldValue("officeCode"))[0]?._id,
      departName: form.getFieldValue("departName"),
      officeCode: form.getFieldValue("officeCode")
    }

    console.log(body)
    const res = await axios.post('/api/depart/add', body)

    setLoading(false);
    navigate('/orgList'); 
    
  }

  const fetchOfficeList = async (params = {}) => {
    setLoading(true);

    const res = await axios.post('/api/office/list', params)
    if(res.data.success) {
      let result = res.data.result

      let newData = {}
      result.forEach(el => newData[el.officeCode] = el.officeName)
      
      setOfficeList(newData)
      setData(result)
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOfficeList();  
  }, []);


  return (
    <div>
    <PageContainer
        ghost
        header={{
          title: formatMessage({id: 'depart.add'}),
          ghost: false,
          extra: [   
          <Button key="1" icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
            {/* {formatMessage({id: 'Back'})} */}
          </Button>,
          <Button key="2" icon={<ReloadOutlined />} onClick={() => form.resetFields()}>
            {/* {formatMessage({id: 'Initialize'})} */}
          </Button>,        
          <Button key="3" type="primary" disabled={disableNext} onClick={() => form.submit()}>
            {formatMessage({id: 'Save'})}
          </Button>
          ],
        }}
        // content={'자주 사용하는 문서를 미리 등록할 수 있습니다.'}
        footer={[
        ]}
    >
    <br />
    <ProCard direction="column" gutter={[0, 16]}>
      <ProForm 
        form={form}
        onFinish={onFinish}
        submitter={{
          // Configure the properties of the button
          resetButtonProps: {
            style: {
              // Hide the reset button
              display: 'none',
            },
          },
          submitButtonProps: {
            style: {
              // Hide the reset button
              display: 'none',
            },
          }
        }}
        initialValues={{
          orgRadio: 'office',
        }}
        onValuesChange={(changeValues) => {
          console.log("onValuesChange called")
          console.log(changeValues)

          // if(changeValues.orgRadio === 'office'){
          //   setHiddenOffice(false)
          //   setHiddenDepart(true)
          //   setHiddenOfficeSelect(true)
            
          // } else if(changeValues.orgRadio === 'depart') {
          //   setHiddenOffice(true)
          //   setHiddenDepart(false)
          //   setHiddenOfficeSelect(false)

          // }

          if (form.getFieldValue("departName") && form.getFieldValue("officeCode")) {
            setDisableNext(false)
          } else {
            setDisableNext(true)
          }

        }}
      >


        {/* <ImgCrop rotate>
          <Upload
            {...props}
            listType="picture-card"
            // listType="picture"
          >
            {fileList.length < 1 && '+ Upload'}
          </Upload>
        </ImgCrop> */}

        <ProFormSelect
          name="officeCode"
          label="어린이집 선택"
          // valueEnum={{
          //   china: 'China',
          //   usa: 'U.S.A',
          // }}
          valueEnum={officeList}
          placeholder="어린이집을 선택하세요."
          fieldProps={{
            size: 'large'          
          }}
          rules={[{ required: true, message: '어린이집을 선택하세요.!' }]}
        />

        <ProFormText
          name="departName"
          label="반명"
          placeholder="반명을 입력하세요."
          fieldProps={{
            size: 'large'          
          }}
          rules={[{ required: true, message: formatMessage({id: 'input.departName'}) }]}
        />

      <br></br>

    </ProForm>
  </ProCard>  
      
  </PageContainer>
  </div>
    
  );
};

export default DepartAdd;
