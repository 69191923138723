import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Space, Button, Form, Avatar, Row, Col } from "antd";
import Highlighter from 'react-highlight-words';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../app/infoSlice';
import { navigate } from '@reach/router';
import { SearchOutlined } from '@ant-design/icons';
import RecordExpander from "./RecordExpander";
import moment from 'moment';
import 'moment/locale/ko';
import {
  UserOutlined,
  ArrowLeftOutlined,
  ReloadOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import { useIntl } from "react-intl";
import * as common from "../../util/common";

import ProCard from '@ant-design/pro-card';
import ProForm, { ProFormUploadDragger, ProFormText, ProFormSelect, ProFormDatePicker } from '@ant-design/pro-form';
import ImgCrop from 'antd-img-crop';

import { Radar } from '@ant-design/plots';

import '@ant-design/pro-card/dist/card.css';
import 'antd/dist/antd.min.css';
import '@ant-design/pro-form/dist/form.css';


const { TextArea } = Input;

const RecordList = ({location}) => {

  const { item, officeCode } = location.state;
  const editorRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { _id } = user;

  const [form] = Form.useForm();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [pagination, setPagination] = useState({current:1, pageSize:100});
  const [disableNext, setDisableNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataRadar, setDataRadar] = useState([]);

  const { formatMessage } = useIntl();

  const fetch = async (params = {}) => {
    setLoading(true);

    const res = await axios.post('/api/record/list', params)
    if(res.data.success) {
      let result = res.data.result
      
      let eval1 = 0;
      let eval2 = 0;
      let eval3 = 0;
      result.forEach(el => {
        eval1 += Number(el.evalItem1) 
        eval2 += Number(el.evalItem2) 
        eval3 += Number(el.evalItem3) 
      })
     
      setData(result)

      let evalData = [
        {
          name: '논리력/사고력',
          star: eval1 / result.length,
        },
        {
          name: '학업성취도',
          star: eval2 / result.length,
        },
        {
          name: '사회성과 표현력',
          star: eval3 / result.length,
        }
      ];

      setDataRadar(evalData)
    }
    setLoading(false);
  };

  useEffect(() => {

    fetch({
      pagination,
      user: item?._id
    });

  }, []);

  const config = {
    data: dataRadar,
    xField: 'name',
    yField: 'star',
    autoFit: true,
    appendPadding: [0, 10, 10, 10],
    meta: {
      star: {
        alias: 'star',
        min: 0,
        max: 100,
        nice: true,
        formatter: (v) => Number(v).toFixed(2),
      },
    },
    xAxis: {
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    point: {
      size: 2,
    },
    area: {},
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  }

  const getColumnSearchProps = dataIndex => ({

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            검색
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            초기화
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    // DB 필터링 사용 시는 주석처리
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
        // setTimeout(
        //   () => searchInput && searchInput.current && searchInput.current.select()
        // )
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[setSearchText(searchText)]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const changeEvalScore = (score) => {
    if (score <= 33) {
      return "하"
    } else if (score <= 66) {
      return "중"
    } else {
      return "상"
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    console.log("handleTableChange called")
    console.log(filters)
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
      user: item?._id
    });
  };

  const columns = [
    {
      title: 'no',
      dataIndex: 'no',
      // sorter: (a, b) => a.no.localeCompare(b.no),
      key: 'no',
      width: '50px',
      // expandable: true,
      render: (text,row) => {
        return (
          <div>
            {row['curri']?.no}
          </div>
        )
      } 
    },
    {
      title: '주제',
      dataIndex: 'subject',
      responsive: ["sm"],
      // sorter: (a, b) => a.subject.localeCompare(b.subject),
      key: 'subject',
      // ...getColumnSearchProps('subject'),
      width: '220px',
      render: (text,row) => {
        return (
          <div>
            {row['curri']?.subject}
          </div>
        )
      } 
    },
    {
      title: '논리/사고력',
      dataIndex: 'evalItem1',
      sorter: (a, b) => a.evalItem1.localeCompare(b.evalItem1),
      key: 'evalItem1',
      width: '120px',
      render: (text,row) => {
        return (
          <div>
            {changeEvalScore(text)}
          </div>
        )
      } 
    },
    {
      title: '학업성취도',
      dataIndex: 'evalItem2',
      sorter: (a, b) => a.evalItem2.localeCompare(b.evalItem2),
      key: 'evalItem2',
      width: '120px',
      render: (text,row) => {
        return (
          <div>
            {changeEvalScore(text)}
          </div>
        )
      } 
    },
    {
      title: '사회성/표현력',
      dataIndex: 'evalItem3',
      sorter: (a, b) => a.evalItem3.localeCompare(b.evalItem3),
      key: 'evalItem3',
      width: '120px',
      render: (text,row) => {
        return (
          <div>
            {changeEvalScore(text)}
          </div>
        )
      } 
    },
    {
      title: '평가자',
      dataIndex: 'rater',
      responsive: ["sm"],
      // sorter: (a, b) => a.rater.localeCompare(b.rater),
      key: 'rater',
      width: '100px',
      render: (text,row) => {
        return (
          <div>
            {row['rater']?.name}
          </div>
        )
      } 
    },
    {
      title: '평가일',
      dataIndex: 'registeredTime',
      responsive: ["sm"],
      sorter: true,
      key: 'registeredTime',
      width: '110px',
      render: (text, row) => {
        return (<font color='#787878'>{moment(row["registeredTime"]).fromNow()}</font>)
      } 
    },
    {
      title: '의견',
      dataIndex: 'comment',
      responsive: ["sm"],
      key: 'comment',
      // width: '200px',
      ...getColumnSearchProps('comment'),
      render: (text, row) => {
        return (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {text}
          </div>
        )
      } 
    }
  ];

  return (
    <div>
    <PageContainer
        ghost
        header={{
          // title: formatMessage({id: 'user.detail'}),
          title: (item?.thumbnails && item?.thumbnails.length > 0) ? <div><Avatar shape="square" src={item?.thumbnails[0].path}/>{item?.name}</div> : <div><Avatar shape="square" icon={<UserOutlined />} />{item?.name}</div>,
          ghost: false,
          extra: [   
          <Button key="12" icon={<SolutionOutlined />} onClick={() => 
            navigate(`/recordAdd`, { state: { item: item } } )
          }>
            {formatMessage({id: 'Evaluation'})}
          </Button>,            
          <Button key="1" icon={<ArrowLeftOutlined />} onClick={() => {
            // window.history.back()
            // navigate("/userList")

            if (officeCode) {
              navigate(`/userList`, { state: { officeCode: officeCode } } );
            } else {
              navigate('/userList');
            }
          }}>
            {/* {formatMessage({id: 'Back'})} */}
          </Button>
          ],
        }}
        // content={'자주 사용하는 문서를 미리 등록할 수 있습니다.'}
        footer={[
        ]}
    >
    <br />

    
    {/* <ProCard><Radar {...config} /></ProCard> */}

    {/* <div style={{textAlign: 'center', width:'100%'}}> */}
      <div style={{width:'100%', height:'200px', textAlign:'center'}}><Radar {...config} /></div>
    {/* </div> */}
    

    {/* <Row gutter={[24, 24]}>
      <Col span={12}>
        <Radar {...config} />
      </Col>
      <Col span={12}>
        <Radar {...config} />
      </Col>
    </Row> */}
      
    {/* <Image.PreviewGroup>
      <Image width={200} src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg" />
     ArrowLeftOutlined
        width={200}
        src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg"
      />
    </Image.PreviewGroup> */}

    <br></br>
    <Table
        rowKey={ item => { return item._id } }
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        defaultExpandedRowKeys={[location.state._id]}
        expandedRowRender={row => <RecordExpander item={row} data={data} setData={setData} />}
        expandRowByClick
        // rowSelection={rowSelection}
        onRow={record => ({
          onClick: e => {
            console.log(`user clicked on row ${record._id}!`);
            // navigate(`/userDetail`, { state: { userId: record._id } } );
          }
        })}
        onChange={handleTableChange}
      />


  </PageContainer>
  </div>
    
  );
};

export default RecordList;
